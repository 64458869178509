import AppTableContainer from "@uni/core/AppTableContainer";
import IntlMessages from "@uni/utility/IntlMessages";
import { message, Modal, Pagination } from "antd";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useIntl } from "react-intl";
import useGetViewModifyItem from "./useGetViewModifyItem";
import usePostBangkeManual from "hooks/apis/bangke/usePostBangKeManual";
import { LimitDefault, OffsetDefault } from "shared/constants/AppConst";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import useRecalculate from "hooks/apis/bangke/useRecalculate";
import { AppContentView } from "@uni";
import AppsContent from "@uni/core/AppsContainer/AppsContent";

const ModifyModal = ({ data, record }, ref) => {
  const { messages } = useIntl();
  const columnConfig = useGetViewModifyItem();
  const { modifyData, setModifyData, formData } = useGenerateContext();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [columnsState, setColumnsState] = useState(() =>
    columnConfig?.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        draggable: column.draggable !== false,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);
  const closeModal = useCallback(() => setIsVisible(false), [setIsVisible]);
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );
  const { mutateAsync, isLoading } = usePostBangkeManual();
  const { mutateAsync: mutateRecalculate } = useRecalculate();

  const [dataRes, setDataRes] = useState([]);

  const [params, setParams] = useState({
    limit: LimitDefault,
    offset: OffsetDefault,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const body = {
          ...data,
          bangke_data: data.bangke_data.find((item) => item._id === record._id),
          all_option: "true",
          url_option: formData.url_option,
          origin_option:
            record?.origin_price > 0 ? "originated" : "non_originated",
        };

        const response = await mutateAsync({ body, variables: params });
        setDataRes(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isVisible) {
      fetchData();
    }
  }, [isVisible, mutateAsync, params]);

  const handleModify = async () => {
    try {
      const indexItem = data.bangke_data.findIndex(
        (item) => item._id === record._id
      );
      const indexData = modifyData.findIndex(
        (item) => item.ecus_input.ecus_id === data.ecus_input.ecus_id
      );

      const newModifyData = [
        ...modifyData.slice(0, indexData),
        {
          ...modifyData[indexData],

          bangke_data: [
            ...modifyData[indexData].bangke_data.slice(0, indexItem),
            {
              ...selectedRowIds[0],
              prevPrice:
                record?.origin_price > 0
                  ? record?.origin_price?.toFixed(3)
                  : record?.non_origin_price?.toFixed(3),
            },
            ...modifyData[indexData].bangke_data.slice(indexItem + 1),
          ],
          prevOriginPrice:
            modifyData[indexData]?.recalculate_args?.total_origin_price,
          prevNonOriginPrice:
            modifyData[indexData]?.recalculate_args?.total_non_origin_price,
        },
        ...modifyData.slice(indexData + 1),
      ];

      setModifyData(newModifyData);
      const resRecalculate = await mutateRecalculate(newModifyData[indexData]);

      setModifyData([
        ...newModifyData.slice(0, indexData),
        {
          ...newModifyData[indexData],
          recalculate_args: resRecalculate?.recalculate_args,
          ecus_input: resRecalculate?.ecus_input,
        },
        ...newModifyData.slice(indexData + 1),
      ]);
      closeModal();
      message;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      width={1200}
      title={<IntlMessages id="determination.determine.listMaterial" />}
      onOk={handleModify}
      bodyStyle={{ height: "600px" }}
      okText={messages["common.confirm"].toString()}
    >
      <div className="wrapper-table">
        <AppTableContainer
          loading={isLoading}
          data={dataRes?.results}
          columns={columnConfig}
          columnsState={columnsState}
          setColumnsState={setColumnsState}
          scroll={{ y: 350 }}
          rowSelection={{
            type: "radio",
            onChange: (_, selectedRows) =>
              setSelectedRowIds(
                selectedRows?.map((row) => {
                  return row;
                })
              ),
          }}
        />
      </div>
      <div className="custom-pagination">
        <Pagination
          defaultPageSize={20}
          current={Math.ceil(params.offset / params.limit) + 1}
          onChange={(page, pageSize) => {
            setParams((prev) => {
              return {
                ...prev,
                limit: pageSize,
                offset: (page - 1) * pageSize,
              };
            });
          }}
          total={dataRes?.count}
          showTotal={(total, range) => {
            return (
              <p>
                {range[0]}-{range[1]} {messages["common.on"]?.toString()}{" "}
                {total} {messages["common.records"]?.toString()}
              </p>
            );
          }}
          showSizeChanger
        />
      </div>
    </Modal>
  );
};

export default forwardRef(ModifyModal);
