import itMessages from "../locales/it_IT.json";
import enUS from "antd/lib/locale/en_US";

const ItLang = {
  messages: {
    ...itMessages,
  },
  antLocale: enUS,
  locale: "it-IT",
};

export default ItLang;
