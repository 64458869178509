import IntlMessages from "@uni/utility/IntlMessages";

const useGetViewModifyItem = () => {
  return [
    {
      title: <IntlMessages id="determination.determine.step4.nvl" />,
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: <IntlMessages id="determination.determine.step4.stk" />,
      dataIndex: "cd_number",
      key: "cd_number",
      width: 180,
    },
    {
      title: <IntlMessages id="determination.determine.step4.date" />,
      dataIndex: "cd_date",
      key: "cd_date",
      width: 180,
    },
    {
      title: <IntlMessages id="determination.determine.step4.hs" />,
      dataIndex: "hs_code",
      key: "hs_code",
      width: 180,
    },
    {
      title: <IntlMessages id="determination.determine.step4.cif" />,
      dataIndex: "unit_price",
      key: "unit_price",
      width: 180,
      render: (data) => data?.toFixed(3),
    },
    {
      title: <IntlMessages id="determination.determine.step4.fromCountry" />,
      dataIndex: "from_country",
      key: "from_country",
      width: 180,
    },
    {
      title: <IntlMessages id="determination.determine.step4.price" />,
      key: "non_origin_price",
      width: 70,
      render: (_, record) => {
        if (record?.origin_price > 0) {
          return record?.origin_price?.toFixed(3);
        }
        if (record?.non_origin_price > 0) {
          return record?.non_origin_price?.toFixed(3);
        }
        return 0;
      },
    },
  ];
};

export default useGetViewModifyItem;
