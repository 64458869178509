import IntlMessages from "@uni/utility/IntlMessages";
import UserActionInfoItem from "@uni/core/AppViewItems/UserActionInfoItem";
import { TIME_RANGE_FILER_ENUM } from "./AppEnums";
import moment from "moment";

export const initialUrl = "/data/exim"; // this url will open after login
export const PageDefault = 1;
export const TakeDefault = 10;

export const LimitDefault = 20;
export const OffsetDefault = 0;

export const TABLE_ACTION_COLUMN_KEY = "action";

export const UserActionColumns = [
  {
    title: <IntlMessages id="common.createBy" />,
    key: "createBy",
    width: 250,
    render: (_data, { createdAt, createdUser }) => {
      const { id, username, s3Profile } = createdUser ?? {};
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={createdAt}
          username={username}
        />
      );
    },
  },
  {
    title: <IntlMessages id="common.updateBy" />,
    key: "updateBy",
    width: 250,
    render: (_data, { updatedAt, updatedUser }) => {
      if (!updatedUser) return null;
      const { id, username, s3Profile } = updatedUser;
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={updatedAt}
          username={username}
        />
      );
    },
  },
];

export const TIME_RANGE_FILTER = new Map([
  [
    TIME_RANGE_FILER_ENUM.THIS_WEEK,
    {
      label: "This week",
      value: {
        startDate: moment().startOf("week"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_WEEK,
    {
      label: "Last week",
      value: {
        startDate: moment().subtract(1, "week").startOf("week"),
        endDate: moment().subtract(1, "week").endOf("week"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_MONTH,
    {
      label: "This month",
      value: {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_MONTH,
    {
      label: "Last month",
      value: {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_YEAR,
    {
      label: "This year",
      value: {
        startDate: moment().startOf("year"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_YEAR,
    {
      label: "Last year",
      value: {
        startDate: moment().subtract(1, "year").startOf("year"),
        endDate: moment().subtract(1, "year").endOf("year"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.CUSTOM,
    {
      label: "Custom time range",
    },
  ],
]);

export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ROLE_MEMBER_NAME = "Member";

export const PASSWORD_RULES =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;

export const USERNAME_RULE = /^[a-zA-Z0-9]{8,}$/;

export const DEFAULT_COLUMN_SORT = "id";

export const COMMON_FORM_ITEM_RULE = {
  REQUIRED: {
    required: true,
    message: "Required",
  },
  PASSWORD: {
    pattern: PASSWORD_RULES,
    message:
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special",
  },
  EMAIL: {
    type: "email",
    message: "The input is not valid E-mail!",
  },
  RE_ENTER_PASSWORD: ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The new password that you entered do not match!")
      );
    },
  }),
  USERNAME: {
    pattern: USERNAME_RULE,
    message:
      "Username must contain 8 or more characters, have not special and space character",
  },
};

export const APP_UPLOAD_FILE_TYPE = {
  PDF: "PDF",
  IMAGE: "IMAGE",
  WORD: "WORD",
  EXCEL: "EXCEL",
};

// docs: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const APP_UPLOAD_FILE_MIME_TYPES = {
  PDF: ["application/pdf"],
  WORD: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  IMAGE: [
    "image/jpeg",
    "image/avif",
    "image/bmp",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
  ],
};

export const DEFAULT_STEP = [
  "Import sheet",
  "Select sheet title",
  "Pick header",
];

export const EXTRA_STEP = {
  MAP_HEADER: "Map headers",
  EXTRA_STEP: "Select begin data date",
};

export const EXTRA_FIELD_STATEMENT_OPTIONS = [
  { label: "Epe", value: "Epe" },
  { label: "Processing", value: "Processing" },
  { label: "Manufacturing", value: "Manufacturing" },
];

export const EXTRA_FIELD_OPTIONS = [
  { label: "Material", value: "Material" },
  { label: "Product", value: "Product" },
  { label: "Semi-product", value: "Semi-product" },
];

export const REQUIRED_IMPORT_EXCEL_KEYS = [
  "contract_date",
  "cd_date",
  "invoice_date",
  "shipping_date",
];

export const GENERATE_STEP = [
  { title: "Basic Setting", desc: "Pre-setting for generating Bang Ke", id: 1 },
  {
    title: "Criteria Setting",
    desc: "Detailed setting for generating Bang Ke",
    id: 2,
  },
  { title: "Genarate", desc: "Final result of generating Bang Ke", id: 3 },
  // { title: "Step 4", desc: "description", id: 4 },
  // { title: "Step 5", desc: "description", id: 5 },
];
export const DOCS_FORM = {
  AC: "AC - FORM E",
  AI: "AIFTA - FORM AI",
  AJ: "AJCEP - FORM AJ",
  VJ: "VJEPA - FORM VJ",
  AA: "AANZFTA - FORM AANZ",
  CP: "CPTPP - FORM CPTPP",
  RC: "RCEP - FORM RCEP",
};

export const LINK_FORMS = {
  formAC: {
    topNoUserInput: 1,
    top: 2,
    bot: 3,
  },
  formA: {},
  // formVJ: {
  //   top: "https://admin.eximhow.com/upload/uploads/23/12/form_vj_top_1.28.docx",
  //   topNoUserInput:
  //     "https://admin.eximhow.com/upload/uploads/23/12/form_vj_top_2.28.docx",
  //   bot: "https://admin.eximhow.com/upload/uploads/23/12/form_vj_bottom_1.4.docx",
  // },
  formAI: {
    topNoUserInput: 4,
    top: 5,
    bot: 6,
  },
};

export const GENERATE_BALANCE_STEP = [
  { title: "Basic Setting", desc: "Pre-setting for generating", id: 1 },
  { title: "Generate", desc: "Final result of generating", id: 2 },
];

export const DATA_TABLE_MODULE = {
  ECUS: "ecus",
  BEGIN: "begin",
  PURCHASE: "purchase",
};
