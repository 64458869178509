import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";

const postBangKeManual = (body, params) => {
  if (body?.data?.[0]?.url_option === "custom") {
    return jwtAxios.post(URL_API.BANG_KE.MANUAL_CUSTOM, body);
  }
  return jwtAxios.post(URL_API.BANG_KE.MANUAL_DEFAULT, body, {
    params,
  });
};

const usePostBangkeManual = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: ({ body, variables }) => postBangKeManual(body, variables),
    onSuccess: () => {
      // message.success(messages["bangke.modifySuccess"]?.toString());
    },
    onError: () => {
      message.error(messages["data.accounting.begin.create.fail"]?.toString());
    },
  });
};

export default usePostBangkeManual;
