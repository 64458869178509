import IntlMessages from "@uni/utility/IntlMessages";
import { convertDateUni } from "@uni/utility/Utils";
import { TABLE_ACTION_COLUMN_KEY } from "shared/constants/AppConst";
import HistoryColumnActionOption from "./HistoryColumnActionOption";
import { CheckCircleOutlined } from "@ant-design/icons";

const useGetHistoryColumn = () => {
  return [
    {
      title: <IntlMessages id="data.exim.ecus.isDeducted" />,
      dataIndex: "is_deducted",
      key: "is_deducted",
      align: "center",
      width: 120,
      render: (_, data) =>
        data.is_deducted ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          ""
        ),
    },
    {
      title: <IntlMessages id="data.exim.ecus.code" />,
      dataIndex: ["ecus_input", "ecus_code"],
      key: "code",
      width: 120,
    },
    {
      title: <IntlMessages id="data.exim.ecus.name" />,
      dataIndex: ["ecus_input", "ecus_item_name"],
      key: "name",
      width: 400,
    },
    {
      title: <IntlMessages id="data.exim.ecus.ntValue" />,
      dataIndex: ["ecus_input", "formula_nt_value"],
      key: "ntValue",
      width: 200,
    },
    {
      title: <IntlMessages id="data.exim.ecus.cdNumber" />,
      dataIndex: ["ecus_input", "ecus_cd_number"],
      key: "cdNumber",
      width: 200,
    },
    {
      title: <IntlMessages id="data.exim.ecus.cdDate" />,
      dataIndex: "cd_date",
      key: "cdDate",
      width: 120,
      render: (_, data) =>
        data?.ecus_input?.ecus_cd_date
          ? convertDateUni(data?.ecus_input?.ecus_cd_date)
          : "",
    },
    {
      title: <IntlMessages id="data.exim.ecus.contractNumber" />,
      dataIndex: ["ecus_input", "ecus_contract_number"],
      key: "ecus_contract_number",
      width: 200,
    },
    {
      title: <IntlMessages id="data.exim.ecus.hsCode" />,
      dataIndex: ["ecus_input", "ecus_hs"],
      key: "ecus_hs",
      width: 200,
    },
    {
      title: <IntlMessages id="data.exim.ecus.totalQuantity" />,
      dataIndex: ["ecus_input", "ecus_total_quantity"],
      key: "ecus_total_quantity",
      width: 120,
    },
    {
      title: <IntlMessages id="common.action" />,
      key: TABLE_ACTION_COLUMN_KEY,
      fixed: "right",
      width: 80,
      render: (_, record) => <HistoryColumnActionOption record={record} />,
    },
  ];
};

export default useGetHistoryColumn;
