import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

const deleteAllFormA = async (body) =>
  await jwtAxios.delete(URL_API.CO_FORM.DELETE_ALL, { data: body });

const useDeleteAllFormA = () => {
  const queryClient = useQueryClient();
  const { messages } = useIntl();
  return useMutation({
    mutationFn: deleteAllFormA,
    onSuccess: () => {
      queryClient.invalidateQueries(URL_API.CO_FORM.GET_ALL);
      message.success(messages["data.ecus.delete.success"]?.toString());
    },
    onError: () => {
      message.error(messages["data.ecus.delete.fail"]?.toString());
    },
  });
};

export default useDeleteAllFormA;
