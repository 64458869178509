import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";

const postBangKeDeminimis = (body) => {
  return jwtAxios.post(URL_API.BANG_KE.DEMINIMIS, body);
};

const usePostBangkeDeminimis = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: postBangKeDeminimis,
    onSuccess: () => {
      message.success(messages["bangke.deminimisSuccess"]?.toString());
    },
    onError: () => {
      message.error(messages["bangke.deminimisFail"]?.toString());
    },
  });
};

export default usePostBangkeDeminimis;
