import {
  COL_EXCEL_WIDTHS_BALANCE,
  COL_EXCEL_WIDTHS_BCQT_STATEMENT15,
  COL_EXCEL_WIDTHS_BEGIN_STATEMENT15,
  COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15,
  COL_EXCEL_WIDTHS_MAKING_STATEMENT15,
  COL_EXCEL_WIDTHS_MAKING_STATEMENT15A,
  COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15,
  COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15,
  COL_WIDTHS_TAKE_OUT_STATEMENT15A,
  MERGE_CELLS_BALANCE,
  MERGE_CELLS_BCQT_STATEMENT15,
  MERGE_CELLS_BCQT_STATEMENT15A,
  MERGE_CELLS_BEGIN_STATEMENT15,
  MERGE_CELLS_INVENTORY_STATEMENT15,
  MERGE_CELLS_MAKING_STATEMENT15,
  MERGE_CELLS_MAKING_STATEMENT15A,
  MERGE_CELLS_TAKE_IN_STATEMENT15,
  MERGE_CELLS_TAKE_IN_STATEMENT15A,
  MERGE_CELLS_TAKE_OUT_STATEMENT15,
  MERGE_CELLS_TAKE_OUT_STATEMENT15A,
} from "shared/constants/BalanceCons";
import * as XLSX from "xlsx-js-style";

const commonFormatCell = {
  font: {
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  border: {
    top: { style: "thin", color: { rgb: "000000" } }, // Top border
    bottom: { style: "thin", color: { rgb: "000000" } }, // Bottom border
    left: { style: "thin", color: { rgb: "000000" } }, // Left border
    right: { style: "thin", color: { rgb: "000000" } }, // Right border
  },
};

const applyBackgroundWS = (ws, sheetData, bg) => {
  for (let i = 0; i < sheetData?.[bg]?.cells.length; i++) {
    ws[sheetData?.[bg]?.cells[i]].s = {
      fill: {
        fgColor: { rgb: sheetData?.[bg]?.bgColor },
      },
      ...commonFormatCell,
    };
  }
};

export const downloadExcelStatement15 = (dataExcel, fileName, messages) => {
  const workbook = XLSX.utils.book_new();

  const convertDataToTable = dataExcel?.map((item, index) => [
    index + 1,
    item?.code || "",
    item?.item_name || "",
    item?.unit || "",
    item?.begin_quantity || "",
    item?.import_quantity || "",
    item?.B13 || "",
    item?.A42 || "",
    item?.export_quantity || "",
    item?.other_export_quantity || "",
    item?.end_quantity || "",
    "",
  ]);

  const dataHeader = [
    [
      messages["data.exim.statement15.no"],
      messages["data.exim.statement15.code"],
      messages["data.exim.statement15.name"],
      messages["data.exim.statement15.unit"],
      messages["data.exim.statement15.beginQuantity"],
      messages["data.exim.statement15.importQuantity"],
      messages["data.exim.statement15.export1"],
      "",
      "",
      "",
      messages["data.exim.statement15.endQuantity"],
      messages["common.notes"],
    ],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      messages["data.exim.statement15.reExportQuantity"],
      messages["data.exim.statement15.rePurposeQuantity"],
      messages["data.exim.statement15.exportToProduceQuantity"],
      messages["data.exim.statement15.otherExportQuantity"],
      "",
      "",
    ],
  ].map((item) =>
    item.map((itemChild) => ({
      v: itemChild,
      t: "s",
      s: {
        ...commonFormatCell,
      },
    }))
  );

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...dataHeader,
    ...convertDataToTable.map((item) =>
      item.map((itemChild) => ({
        v: itemChild,
        t: "s",
        s: {
          border: { ...commonFormatCell.border },
        },
      }))
    ),
  ]);

  worksheet["!merges"] = MERGE_CELLS_MAKING_STATEMENT15.map((merge) => {
    return {
      s: { r: merge.row, c: merge.col },
      e: {
        r: merge.row + merge.rowspan - 1,
        c: merge.col + merge.colspan - 1,
      },
    };
  });

  worksheet["!cols"] = COL_EXCEL_WIDTHS_MAKING_STATEMENT15.map((width) => ({
    width,
  }));

  const sheetName = fileName;
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelStatement15A = (dataExcel, fileName, messages) => {
  const workbook = XLSX.utils.book_new();

  const convertDataToTable = dataExcel?.map((item, index) => [
    index + 1,
    item?.code || "",
    item?.item_name || "",
    item?.unit || "",
    item?.begin_quantity || "",
    item?.import_quantity || "",
    item?.A42 || "",
    item?.export_quantity || "",
    item?.other_export_quantity || "",
    item?.end_quantity || "",
    "",
  ]);

  const dataHeader = [
    [
      messages["data.exim.statement15.no"],
      messages["data.exim.statement15A.code"],
      messages["data.exim.statement15A.name"],
      messages["data.exim.statement15A.unit"],
      messages["data.exim.statement15A.beginQuantity"],
      messages["data.exim.statement15A.importQuantity"],
      messages["data.exim.statement15.export2"],
      "",
      "",
      messages["data.exim.statement15A.endQuantity"],
      messages["common.notes"],
    ],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      messages["data.exim.statement15A.rePurposeQuantity"],
      messages["data.exim.statement15A.exportQuantity"],
      messages["data.exim.statement15A.otherExportQuantity"],
      "",
      "",
    ],
  ].map((item) =>
    item.map((itemChild) => ({
      v: itemChild,
      t: "s",
      s: {
        ...commonFormatCell,
      },
    }))
  );

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...dataHeader,
    ...convertDataToTable.map((item) =>
      item.map((itemChild) => ({
        v: itemChild,
        t: "s",
        s: {
          border: { ...commonFormatCell.border },
        },
      }))
    ),
  ]);

  worksheet["!merges"] = MERGE_CELLS_MAKING_STATEMENT15A.map((merge) => {
    return {
      s: { r: merge.row, c: merge.col },
      e: {
        r: merge.row + merge.rowspan - 1,
        c: merge.col + merge.colspan - 1,
      },
    };
  });

  worksheet["!cols"] = COL_EXCEL_WIDTHS_MAKING_STATEMENT15A.map((width) => ({
    width,
  }));

  const sheetName = fileName;
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelBalance = (dataExcel, fileName, messages) => {
  const workbook = XLSX.utils.book_new();

  const convertDataToTable = dataExcel?.map((item, index) => [
    item?.material_code || "",
    item?.item_name || "",
    item?.unit_ecus || "",
    item?.unit || "",
    item?.begin_quantity_15 || "",
    item?.begin_quantity_15A || "",
    item?.import_quantity_15 || "",

    item?.re_export_quantity_15 || "",
    item?.re_purpose_quantity_15 || "",
    item?.re_purpose_quantity_15A || "",

    item?.other_export_quantity_15 || "",
    item?.other_export_quantity_15A || "",

    item?.export_quantity || "",

    item?.ECUS || "",
    item?.end_quantity_15 || "",
    item?.end_quantity_15A || "",
    item?.BALANCE || "",
    item?.IMPOSED_QUANTITY || "",

    item?.import_tax || "",
    item?.vat_tax || "",

    item?.IMPORT_TAX_AMT || "",
    item?.VAT_AMT || "",
    item?.PENALTY || "",
    item?.TOTAL_TAX_AMT || "",
  ]);

  const dataHeader = [
    [
      "ITEM CODE",
      "ITEM NAME",
      "UNIT",
      "",
      "BEGIN",
      "",
      "IMPORT",
      "Re-export",
      "Re-purpose",
      "",
      "Other out put",
      "",
      "EXPORT",
      "ECUS",
      "ENDING",
      "",
      "BALANCE",
      "IMPOSE QUANTITY",
      "IMPORT TAX AVERAGE",
      "VAT AVERAGE",
      "IMPORT TAX AMT",
      "VAT AMT",
      "PENALTY",
      "TOTAL TAX AMT",
    ],
    [
      "",
      "",
      "ECUS",
      "STATEMENT",
      "RM",
      "FG",
      "",
      "",
      "RM",
      "FG",
      "RM",
      "FG",
      "",
      "",
      "RM",
      "FG",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
  ].map((item) =>
    item.map((itemChild) => ({
      v: itemChild,
      t: "s",
      s: {
        ...commonFormatCell,
      },
    }))
  );

  const worksheet = XLSX.utils.aoa_to_sheet([
    ...dataHeader,
    ...convertDataToTable.map((item) =>
      item.map((itemChild) => ({
        v: itemChild,
        t: "s",
        s: {
          border: { ...commonFormatCell.border },
        },
      }))
    ),
  ]);

  worksheet["!merges"] = MERGE_CELLS_BALANCE.map((merge) => {
    return {
      s: { r: merge.row, c: merge.col },
      e: {
        r: merge.row + merge.rowspan - 1,
        c: merge.col + merge.colspan - 1,
      },
    };
  });

  worksheet["!cols"] = COL_EXCEL_WIDTHS_BALANCE.map((width) => ({
    width,
  }));

  const sheetName = fileName;
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelCheckingStatement15 = (
  dataExcel,
  fileName,
  messages
) => {
  const workbook = XLSX.utils.book_new();

  const dataListWorkSheet = [
    {
      name: "BCQT",
      dataHeader: [
        [
          "NO",
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN",
          "TAKE IN",
          "TAKE OUT",
          "",
          "",
          "",
          "ENDING INVENTORY",
          "NOTE",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "RE-EXPORT",
          "RE-PURPOSE",
          "OUTPUT PRODUCTION",
          "OTHER OUTPUT",
          "",
          "",
        ],
        [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "(11)=(5)+(6)-(7)-(8)-(9)-(10)",
          "12",
        ],
      ],
      convertDataToTable: dataExcel?.current_statement_sheet?.map(
        (item, index) => [
          index + 1,
          item?.code || "",
          item?.item_name || "",
          item?.unit || "",
          item?.begin_quantity || "",
          item?.import_quantity || "",
          item?.re_export_quantity || "",
          item?.re_purpose_quantity || "",
          item?.export_to_produce_quantity || "",
          item?.other_export_quantity_statement || "",
          item?.end_quantity || "",
          "",
        ]
      ),
      mergeCell: MERGE_CELLS_BCQT_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_BCQT_STATEMENT15,
      bgOrange: {
        cells: ["E1", "F1", "G1", "K1", "G2", "H2", "I2", "J2"],
        bgColor: "ffc000",
      },
    },
    {
      name: "BEGIN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN STATEMENT",
          "ENDING INVENTORY LAST STATEMENT",
          "BEGIN ACC",
          "BEGIN WH",
          "GAP",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "STATEMENT - LAST STATEMENT",
          "ACC - STATEMENT ",
          "WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.begin_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.begin_quantity || "",
        item?.end_quantity || "",
        item?.begin_quantity_acc || "",
        item?.begin_quantity_wh || "",
        item?.gap_bcqt || "",
        item?.gap_bcqt_acc || "",
        item?.gap_bcqt_wh || "",
      ]),
      mergeCell: MERGE_CELLS_BEGIN_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_BEGIN_STATEMENT15,
      bgOrange: {
        cells: ["D1"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["E1", "F1", "G1"],
        bgColor: "83cceb",
      },
    },
    {
      name: "TAKE IN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE IN STATEMENT ",
          "E21/E31/(E11+E15)",
          "TAKE IN ACC",
          "TAKE IN WH",
          "TAKE IN PURCHASE",
          "GAP",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "E21/E31/(E11+E15) - STATEMENT ",
          "ACC - STATEMENT ",
          "WH - STATEMENT",
          "PURCHASE - STATEMENT ",
        ],
      ],
      convertDataToTable: dataExcel?.take_in_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.import_quantity || "",
        item?.E21 || "",
        item?.import_quantity_acc || "",
        item?.import_quantity_wh || "",
        item?.total_quantity || "",
        item?.gap_E21_E31_E11_E15 || "",
        item?.gap_import_quantity_acc || "",
        item?.gap_import_quantity_wh || "",
        item?.gap_total_quantity || "",
      ]),
      mergeCell: MERGE_CELLS_TAKE_IN_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15,
      bgOrange: {
        cells: ["D1"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["E1", "F1", "G1", "H1"],
        bgColor: "83cceb",
      },
    },
    {
      name: "TAKE OUT",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE OUT STATEMENT ",
          "",
          "",
          "",
          "B13",
          "A42",
          "TAKE OUT ACC",
          "",
          "TAKE OUT WH",
          "GAP",
          "",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "Re-Export",
          "Re-Purpose",
          "Output Production",
          "Other Output",
          "",
          "",
          "Out put production",
          "Other output",
          "Out put production",
          "Re-Export",
          "Re-purpose",
          "Out put production",
          "",
          "Other output",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "B13 - STATEMENT ",
          "A42 - STATEMENT ",
          "ACC - STATEMENT ",
          "WH - STATEMENT ",
          "ACC - STATEMENT ",
        ],
      ],
      convertDataToTable: dataExcel?.take_out_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.re_export_quantity || "",
        item?.re_purpose_quantity || "",
        item?.export_to_produce_quantity || "",
        item?.other_export_quantity_statement || "",
        item?.B13 || "",
        item?.A42 || "",
        item?.export_quantity_acc || "",
        item?.other_export_quantity_remain || "",
        item?.export_quantity_wh || "",
        item?.gap_re_export || "",
        item?.gap_re_purpose || "",
        item?.gap_export_acc || "",
        item?.gap_export_wh || "",
        item?.gap_other_export || "",
      ]),
      mergeCell: MERGE_CELLS_TAKE_OUT_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15,
      bgOrange: {
        cells: ["D1", "D2", "E2", "F2", "G2"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["H1", "I1", "J1", "L1", "J2", "K2", "L2"],
        bgColor: "83cceb",
      },
    },
    {
      name: "INVENTORY",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "ENDING INVENTORY STATEMENT ",
          "ENDING INVENTORY ACC",
          "ENDING INVENTORY WH",
          "PHYSICAL INVENTORY ACC",
          "PHYSICAL INVENTORY WH",
          "GAP",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ACC - STATEMENT",
          "WH - STATEMENT",
          "PHYSICAL INVENTORY ACC - STATEMENT",
          "PHYSICAL INVENTORY WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.inventory_sheet?.map((item, index) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.end_quantity || "",
        item?.end_quantity_acc || "",
        item?.end_quantity_wh || "",
        item?.end_quantity_ph_acc || "",
        item?.end_quantity_ph_wh || "",
        item?.gap_end_acc || "",
        item?.gap_end_wh || "",
        item?.gap_end_ph_acc || "",
        item?.gap_end_ph_wh || "",
      ]),
      mergeCell: MERGE_CELLS_INVENTORY_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15,
      bgOrange: {
        cells: ["D1"],
        bgColor: "ffc000",
      },
      bgBlue: {
        cells: ["E1", "F1", "G1", "H1"],
        bgColor: "83cceb",
      },
    },
  ];

  // iterable to create & add sheet to excel
  dataListWorkSheet.forEach((sheetData) => {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ...sheetData.dataHeader.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            ...commonFormatCell,
          },
        }))
      ),
      ...sheetData.convertDataToTable.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            border: { ...commonFormatCell.border },
          },
        }))
      ),
    ]);

    worksheet["!merges"] = sheetData.mergeCell.map((merge) => {
      return {
        s: { r: merge.row, c: merge.col },
        e: {
          r: merge.row + merge.rowspan - 1,
          c: merge.col + merge.colspan - 1,
        },
      };
    });

    worksheet["!cols"] = sheetData.colWidth.map((width) => ({
      width,
    }));

    // Apply background color
    if (sheetData?.bgOrange || sheetData?.bgBlue) {
      if (sheetData?.bgOrange) {
        applyBackgroundWS(worksheet, sheetData, "bgOrange");
      }
      if (sheetData?.bgBlue) {
        applyBackgroundWS(worksheet, sheetData, "bgBlue");
      }
    }

    const sheetName = sheetData.name;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};

export const downloadExcelCheckingStatement15A = (
  dataExcel,
  fileName,
  messages
) => {
  const workbook = XLSX.utils.book_new();

  const dataListWorkSheet = [
    {
      name: "BCQT",
      dataHeader: [
        [
          "NO",
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN",
          "TAKE IN",
          "TAKE OUT",
          "",
          "",
          "ENDING INVENTORY",
          "NOTE",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "RE-PURPOSE",
          "EXPORT",
          "OTHER OUTPUT",
          "",
          "",
        ],
        [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "(10)=(5)+(6)-(7)-(8)-(9)",
          "11",
        ],
      ],
      convertDataToTable: dataExcel?.current_statement_sheet?.map(
        (item, index) => [
          index + 1,
          item?.code || "",
          item?.item_name || "",
          item?.unit || "",
          item?.begin_quantity || "",
          item?.import_quantity || "",
          item?.re_purpose_quantity || "",
          item?.export_quantity || "",
          item?.other_export_quantity_statement || "",
          item?.end_quantity || "",
          "",
        ]
      ),
      mergeCell: MERGE_CELLS_BCQT_STATEMENT15A,
      colWidth: COL_EXCEL_WIDTHS_BCQT_STATEMENT15,
      bgYellow: {
        cells: ["E1", "F1", "G1", "J1", "G2", "H2", "I2"],
        bgColor: "ffff00",
      },
    },
    {
      name: "BEGIN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "BEGIN STATEMENT",
          "ENDING INVENTORY LAST STATEMENT",
          "BEGIN ACC",
          "BEGIN WH",
          "GAP",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "STATEMENT - LAST STATEMENT",
          "ACC - STATEMENT ",
          "WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.begin_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.begin_quantity || "",
        item?.end_quantity || "",
        item?.begin_quantity_acc || "",
        item?.begin_quantity_wh || "",
        item?.gap_bcqt || "",
        item?.gap_bcqt_acc || "",
        item?.gap_bcqt_wh || "",
      ]),
      mergeCell: MERGE_CELLS_BEGIN_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_BEGIN_STATEMENT15,
      bgYellow: {
        cells: ["D1"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["E1", "F1", "G1"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "TAKE IN",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE IN STATEMENT ",
          "TAKE IN ACC",
          "TAKE IN WH",
          "GAP",
          "",
        ],
        ["", "", "", "", "", "", "ACC - STATEMENT ", "WH - STATEMENT"],
      ],
      convertDataToTable: dataExcel?.take_in_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.import_quantity || "",
        item?.import_quantity_acc || "",
        item?.import_quantity_wh || "",
        item?.gap_total_quantity || "",
        item?.gap_import_quantity_wh || "",
      ]),
      mergeCell: MERGE_CELLS_TAKE_IN_STATEMENT15A,
      colWidth: COL_EXCEL_WIDTHS_TAKE_IN_STATEMENT15,
      bgYellow: {
        cells: ["D1"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["E1", "F1"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "TAKE OUT",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "TAKE OUT STATEMENT ",
          "",
          "",
          "E52/E62/E42",
          "TAKE OUT ACC",
          "",
          "TAKE OUT WH",
          "TAKE OUT SALE",
          "A42",
          "GAP",
          "",
          "",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "Export",
          "Re-purpose",
          "Other output",
          "",
          "Export",
          "Other output",
          "Export",
          "Export",
          "",
          "Export",
          "",
          "",
          "",
          "Re-purpose",
          "Other output",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ECUS - STATEMENT ",
          "ACC - STATEMENT ",
          "WH - STATEMENT ",
          "SALE - STATEMENT ",
          "A42 - STATEMENT ",
          "ACC - STATEMENT ",
        ],
      ],
      convertDataToTable: dataExcel?.take_out_sheet?.map((item) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.export_quantity || "",
        item?.re_purpose_quantity || "",
        item?.other_export_quantity_statement || "",
        item?.E52_E42_E62 || "",
        item?.export_quantity_acc || "",
        item?.other_export_quantity_remain || "",
        item?.export_quantity_wh || "",
        item?.export_quantity_sell || "",
        item?.A42 || "",
        item?.gap_export_ecus || "",
        item?.gap_export_acc || "",
        item?.gap_export_wh || "",
        item?.gap_export_sell || "",
        item?.gap_re_purpose || "",
        item?.gap_other_export || "",
      ]),
      mergeCell: MERGE_CELLS_TAKE_OUT_STATEMENT15A,
      colWidth: COL_EXCEL_WIDTHS_TAKE_OUT_STATEMENT15,
      bgYellow: {
        cells: ["D1", "D2", "E2", "F2"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["G1", "H1", "J1", "K1", "L1", "H2", "I2", "J2", "K2"],
        bgColor: "daf2d0",
      },
    },
    {
      name: "INVENTORY",
      dataHeader: [
        [
          "CODE",
          "NAME",
          "UNIT",
          "ENDING INVENTORY STATEMENT ",
          "ENDING INVENTORY ACC",
          "ENDING INVENTORY WH",
          "PHYSICAL INVENTORY ACC",
          "PHYSICAL INVENTORY WH",
          "GAP",
          "",
          "",
          "",
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "ACC - STATEMENT",
          "WH - STATEMENT",
          "PHYSICAL INVENTORY ACC - STATEMENT",
          "PHYSICAL INVENTORY WH - STATEMENT",
        ],
      ],
      convertDataToTable: dataExcel?.inventory_sheet?.map((item, index) => [
        item?.code || "",
        item?.item_name || "",
        item?.unit || "",
        item?.end_quantity || "",
        item?.end_quantity_acc || "",
        item?.end_quantity_wh || "",
        item?.end_quantity_ph_acc || "",
        item?.end_quantity_ph_wh || "",
        item?.gap_end_acc || "",
        item?.gap_end_wh || "",
        item?.gap_end_ph_acc || "",
        item?.gap_end_ph_wh || "",
      ]),
      mergeCell: MERGE_CELLS_INVENTORY_STATEMENT15,
      colWidth: COL_EXCEL_WIDTHS_INVENTORY_STATEMENT15,
      bgYellow: {
        cells: ["D1"],
        bgColor: "ffff00",
      },
      bgGreen: {
        cells: ["E1", "F1", "G1", "H1"],
        bgColor: "daf2d0",
      },
    },
  ];

  // iterable to create & add sheet to excel
  dataListWorkSheet.forEach((sheetData) => {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ...sheetData.dataHeader.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            ...commonFormatCell,
          },
        }))
      ),
      ...sheetData.convertDataToTable.map((item) =>
        item.map((itemChild) => ({
          v: itemChild,
          t: "s",
          s: {
            border: { ...commonFormatCell.border },
          },
        }))
      ),
    ]);

    worksheet["!merges"] = sheetData.mergeCell.map((merge) => {
      return {
        s: { r: merge.row, c: merge.col },
        e: {
          r: merge.row + merge.rowspan - 1,
          c: merge.col + merge.colspan - 1,
        },
      };
    });

    worksheet["!cols"] = sheetData.colWidth.map((width) => ({
      width,
    }));

    // Apply background color
    if (sheetData?.bgYellow || sheetData?.bgGreen) {
      if (sheetData?.bgYellow) {
        applyBackgroundWS(worksheet, sheetData, "bgYellow");
      }
      if (sheetData?.bgGreen) {
        applyBackgroundWS(worksheet, sheetData, "bgGreen");
      }
    }

    const sheetName = sheetData.name;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}Export.xlsx`);
};
