export const getHeaderFromKey = () => (messages) => ({
  code: messages["determination.determine.code"],
  hs_code: messages["determination.determine.hsCode"],
  invoice_date: messages["determination.determine.invoiceDate"],
  invoice_no: messages["determination.determine.invoiceNo"],
  mark_package: messages["determination.determine.mark_package"],
  name: messages["determination.determine.itemName"],
  nt_value: messages["determination.determine.ntValue"],
  origin_criteria: messages["determination.determine.criteria"],
  total_quantity: messages["determination.determine.totalQuantity"],
});
