import { PERMISSIONS } from "shared/constants/AppEnums";

const routesConfig = [
  // {
  //   id: "dashboards",
  //   title: "Dashboards",
  //   messageId: "sidebar.dashboards",
  //   // icon: <AppstoreOutlined />,
  //   path: "/dashboards",
  // },
  {
    id: "data",
    title: "DATA",
    messageId: "sidebar.data",
    type: "collapse",
    scopes: [
      PERMISSIONS.VIEW_ECUS,
      PERMISSIONS.VIEW_STATEMENT15,
      PERMISSIONS.VIEW_STATEMENT15A,
      PERMISSIONS.VIEW_PURCHASE,
      PERMISSIONS.VIEW_PURCHASE_DO,
      PERMISSIONS.VIEW_BEGIN,
      PERMISSIONS.VIEW_ACCOUNT_SELL,
      PERMISSIONS.VIEW_ACCOUNT_PHYSICAL_INVENTORY,
      PERMISSIONS.VIEW_ACCOUNT_IOB,
      PERMISSIONS.VIEW_OTHER_OUTPUT,
      PERMISSIONS.VIEW_WAREHOUSE_PHYSICAL_INVENTORY,
      PERMISSIONS.VIEW_WAREHOUSE_IOB,
      PERMISSIONS.VIEW_BOM,
      PERMISSIONS.VIEW_BOM_EXW,
      PERMISSIONS.VIEW_DESTROY,
    ],
    children: [
      {
        id: "exim",
        title: "Exim",
        messageId: "sidebar.data.exim",
        // icon: <UserOutlined />,
        path: "/data/exim",
        scopes: [
          PERMISSIONS.VIEW_ECUS,
          PERMISSIONS.VIEW_STATEMENT15,
          PERMISSIONS.VIEW_STATEMENT15A,
        ],
      },
      {
        id: "accounting",
        title: "Accounting",
        messageId: "sidebar.data.accounting",
        // icon: <UserOutlined />,
        path: "/data/accounting",
        scopes: [
          PERMISSIONS.VIEW_PURCHASE,
          PERMISSIONS.VIEW_PURCHASE_DO,
          PERMISSIONS.VIEW_BEGIN,
          PERMISSIONS.VIEW_ACCOUNT_PHYSICAL_INVENTORY,
          PERMISSIONS.VIEW_ACCOUNT_IOB,
          PERMISSIONS.VIEW_OTHER_OUTPUT,
        ],
      },
      {
        id: "warehouse",
        title: "Warehouse",
        messageId: "sidebar.data.warehouse",
        path: "/data/warehouse",
        scopes: [
          PERMISSIONS.VIEW_WAREHOUSE_PHYSICAL_INVENTORY,
          PERMISSIONS.VIEW_WAREHOUSE_IOB,
        ],
      },
      {
        id: "production",
        title: "Production",
        messageId: "sidebar.data.production",
        path: "/data/production",
      },
      {
        id: "bom",
        title: "BOM",
        messageId: "sidebar.data.bom",
        // icon: <UserOutlined />,
        path: "/data/bom",
        scopes: [PERMISSIONS.VIEW_BOM, PERMISSIONS.VIEW_BOM_EXW],
      },
      {
        id: "others",
        title: "Others",
        messageId: "sidebar.data.others",
        path: "/data/others",
        scopes: [PERMISSIONS.VIEW_ACCOUNT_SELL, PERMISSIONS.VIEW_DESTROY],
      },
    ],
  },
  {
    id: "master",
    title: "Master",
    messageId: "sidebar.master",
    type: "collapse",
    scopes: [
      PERMISSIONS.VIEW_PARTNER,
      PERMISSIONS.VIEW_COMMODITY,
      PERMISSIONS.VIEW_BOM_CHANGE,
      PERMISSIONS.VIEW_HS_DIFFERENCE_YEAR,
      PERMISSIONS.VIEW_HS_DIFFERENCE_PARTNER,
      PERMISSIONS.VIEW_PRODUCTION_PROCESS,
      PERMISSIONS.VIEW_FG_DIFFERENCE,
    ],
    children: [
      {
        id: "partner",
        title: "Partner",
        messageId: "sidebar.master.partner",
        path: "/master/partner",
        scopes: [PERMISSIONS.VIEW_PARTNER],
      },
      {
        id: "commodity",
        title: "Commodity",
        messageId: "sidebar.master.commodity",
        path: "/master/commodity",
        scopes: [PERMISSIONS.VIEW_COMMODITY],
      },
      {
        id: "bomChange",
        title: "Bom Change",
        messageId: "sidebar.master.bomChange",
        path: "/master/bom-change",
        scopes: [PERMISSIONS.VIEW_BOM_CHANGE],
      },
      {
        id: "hsYear",
        title: "HS Different - Year",
        messageId: "sidebar.master.hsYear",
        path: "/master/hs-year",
        scopes: [PERMISSIONS.VIEW_HS_DIFFERENCE_YEAR],
      },
      {
        id: "hsPartner",
        title: "HS Different - Partner",
        messageId: "sidebar.master.hsPartner",
        path: "/master/hs-partner",
        scopes: [PERMISSIONS.VIEW_HS_DIFFERENCE_PARTNER],
      },
      {
        id: "productionProcess",
        title: "Production Process",
        messageId: "sidebar.master.productionProcess",
        path: "/master/production-process",
        scopes: [PERMISSIONS.VIEW_PRODUCTION_PROCESS],
      },
      {
        id: "FGDifferent",
        title: "FG Different",
        messageId: "sidebar.master.FGDifferent",
        path: "/master/FG-different",
        scopes: [PERMISSIONS.VIEW_FG_DIFFERENCE],
      },
      {
        id: "preset",
        title: "Preset",
        messageId: "sidebar.master.preset",
        path: "/master/preset",
      },
    ],
  },
  {
    id: "transaction",
    title: "Transaction",
    messageId: "sidebar.transaction",
    type: "collapse",
    children: [
      {
        id: "materialPrice",
        title: "Material Price",
        messageId: "sidebar.transaction.materialPrice",
        path: "/transaction/material-price",
      },
      {
        id: "productPrice",
        title: "Product Price",
        messageId: "sidebar.transaction.productPrice",
        path: "/transaction/product-price",
      },
      {
        id: "inventory",
        title: "Inventory",
        messageId: "sidebar.transaction.inventory",
        path: "/transaction/inventory",
      },
    ],
  },
  {
    id: "determination",
    title: "Bang ke",
    messageId: "sidebar.determination",
    type: "collapse",
    scopes: [PERMISSIONS.VIEW_ECUS, PERMISSIONS.VIEW_BANGKE_HISTORY],
    children: [
      {
        id: "determine",
        title: "Bang ke",
        messageId: "sidebar.determination.determine",
        path: "/determination/determine",
        scopes: [PERMISSIONS.VIEW_ECUS],
      },
      {
        id: "history",
        title: "Bang ke Result",
        messageId: "sidebar.determination.history",
        path: "/determination/history",
        scopes: [PERMISSIONS.VIEW_BANGKE_HISTORY],
      },
    ],
  },
  {
    id: "balance",
    title: "Balance",
    messageId: "sidebar.balance",
    type: "collapse",
    children: [
      {
        id: "management-code",
        title: "Management Code",
        messageId: "sidebar.balance.managementCode",
        path: "/balance/management-code",
      },
      {
        id: "tax-risk",
        title: "Tax Risk",
        messageId: "sidebar.balance.taxRisk",
        path: "/balance/tax-risk",
      },
      {
        id: "checking-statement",
        title: "Checking Statement",
        messageId: "sidebar.balance.checkingStatement",
        path: "/balance/checking-statement",
      },
      {
        id: "balance-statement",
        title: "Balance Statement",
        messageId: "sidebar.balance.balanceStatement",
        path: "/balance/balance-statement",
      },
      {
        id: "making-statement",
        title: "Making Statement",
        messageId: "sidebar.balance.makingStatement",
        path: "/balance/making-statement",
      },
    ],
  },
  {
    id: "fta",
    title: "FTA",
    messageId: "sidebar.fta",
    type: "collapse",
    scopes: [
      PERMISSIONS.VIEW_CO,
      PERMISSIONS.VIEW_ANNEX,
      PERMISSIONS.VIEW_DEDUCTION_HISTORY,
      PERMISSIONS.VIEW_ECUS,
    ],
    children: [
      // {
      //   id: "invoice",
      //   title: "FTA Invoice",
      //   messageId: "sidebar.fta.invoice",
      //   icon: <UserOutlined />,
      //   path: "/fta/invoice",
      // },
      {
        id: "co-list",
        title: "Inssurane C/O",
        messageId: "sidebar.fta.coList",
        // icon: <UserOutlined />,
        path: "/fta/co-list",
        scopes: [PERMISSIONS.VIEW_CO],
      },
      {
        id: "annex",
        title: "ANNEX-10",
        messageId: "sidebar.fta.annex",
        // icon: <UserOutlined />,
        path: "/fta/annex",
        scopes: [PERMISSIONS.VIEW_ANNEX],
      },
      {
        id: "psr",
        title: "PSR",
        messageId: "sidebar.fta.psr",
        // icon: <UserOutlined />,
        path: "/fta/psr",
      },
      {
        id: "bang-ke",
        title: "Bang ke",
        messageId: "sidebar.fta.bangke",
        path: "/fta/ecus-stock",
        scopes: [PERMISSIONS.VIEW_DEDUCTION_HISTORY],
      },
      {
        id: "form-a",
        title: "Form A",
        messageId: "sidebar.fta.formA",
        path: "/fta/ecus-co-form",
        scopes: [PERMISSIONS.VIEW_ECUS],
      },
    ],
  },

  {
    id: "company-setting",
    title: "Company Setting",
    messageId: "sidebar.companySetting",
    path: "/company-setting",
  },
  {
    id: "system",
    messageId: "sidebar.setting",
    type: "collapse",
    children: [
      {
        id: "settings",
        title: "Settings",
        messageId: "sidebar.setting.account-profile",
        path: "/setting/account-profile",
      },
    ],
  },
];
export default routesConfig;
