import { Modal, Steps } from "antd";
import React, {
  forwardRef,
  lazy,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { GENERATE_STEP } from "shared/constants/AppConst";
import "./index.style.less";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import FormItemListSkeleton from "@uni/core/AppSkeleton/FormItemListSkeleton";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
const StepOne = lazy(() => import("./StepOne"));
// const StepTwo = lazy(() => import("./StepTwo"));
// const StepThree = lazy(() => import("./StepThree"));

const GenerateModal = forwardRef(({ id, setSelectedGoodsRowIds }, ref) => {
  const {
    currentStep,
    setCurrentStep,
    setFormData,
    isLoading,
    setDeminimisData,
  } = useGenerateContext();
  const { setDataRow } = useGenerateContext();

  const [isVisible, setIsVisible] = useState(false);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  const closeModal = useCallback(() => {
    setIsVisible(false);
    setFormData({});
    setCurrentStep(0);
    setSelectedGoodsRowIds([]);
    setDataRow([]);
    setDeminimisData(null);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );
  const CloseModal = () => {
    closeModal();
    return <></>;
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepThree />;
      case 3:
        return <StepFour />;
      case 4:
        return <CloseModal />;
      default:
        return <></>;
    }
  };
  return (
    <Modal
      style={{ top: 44 }}
      open={isVisible}
      bodyStyle={{
        height: "85vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      onCancel={closeModal}
      footer={null}
      width={"90%"}
      maskClosable={false}
      title={"Generate Bang Ke"}
      className="modal"
      destroyOnClose={true}
    >
      <div className="modal-step-bangke">
        <Steps
          size="small"
          current={currentStep}
          items={GENERATE_STEP.map((item) => ({
            key: item.id,
            title: item.title,
          }))}
        />
      </div>
      {currentStep !== GENERATE_STEP.length && (
        <>
          <h2 style={{ color: "#2196F3" }}>
            {GENERATE_STEP[currentStep]?.title}
          </h2>
          <p>{GENERATE_STEP[currentStep]?.desc}</p>
        </>
      )}
      {isLoading ? <FormItemListSkeleton /> : renderStep()}
    </Modal>
  );
});

export default GenerateModal;
