import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import IntlMessages from "@uni/utility/IntlMessages";
import { useIntl } from "react-intl";
import { AppRowContainer } from "@uni";
import { MinusCircleOutlined } from "@ant-design/icons";
import usePostBangkeDeminimis from "hooks/apis/bangke/usePostBangKeDeminimis";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";

const DeminimisModal = ({ data }, ref) => {
  const { messages } = useIntl();
  const { mutateAsync, data: deminimisData } = usePostBangkeDeminimis();
  const { companyManagement } = useLayoutContext();
  const { setDeminimisData } = useGenerateContext();

  const { bangke_data, recalculate_args, ecus_input } = data;

  const [form] = Form.useForm();

  const [isVisible, setIsVisible] = useState(false);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);
  const closeModal = useCallback(
    () => (setIsVisible(false), form.resetFields()),
    [setIsVisible]
  );
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  const handleDeminimis = () => {
    form.validateFields().then((values) => {
      try {
        let deminimis_weight;
        if (values.deminimis === "deminimis_weight") {
          deminimis_weight = Object.keys(values)
            .filter(
              (key) =>
                key !== "deminimis" &&
                key !== "deminimis_percent" &&
                Number(values[key]) > 0
            )
            .map((item) => ({
              material_code: item,
              material_weight: Number(values[item]),
            }));
        }

        const formattedData = {
          folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
          company_slug: companyManagement?.company?.detail?.company?.slug || "",
          bangke_data,
          recalculate_args,
          ecus_input,
          deminimis_weight:
            values.deminimis === "deminimis_weight" ? deminimis_weight : [],
          is_weight: values.deminimis === "deminimis_weight",
          deminimis_percent: Number(values.deminimis_percent),
        };

        mutateAsync(formattedData);
        setIsVisible(false);
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  useEffect(() => {
    setDeminimisData((prev) => ({
      ...prev,
      [ecus_input.ecus_code]: deminimisData,
    }));
  }, [deminimisData]);

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      width={600}
      title={<IntlMessages id="determination.determine.deminimis" />}
      onOk={handleDeminimis}
      bodyStyle={{ height: "600px" }}
      okText={messages["common.confirm"].toString()}
    >
      <Form layout="horizontal" form={form}>
        <AppRowContainer style={{ position: "relative" }}>
          <Col md={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="deminimis"
              label="Deminimis"
            >
              <Select
                options={[
                  { label: "Deminimis Value", value: "deminimis_value" },
                  { label: "Deminimis Weight", value: "deminimis_weight" },
                ]}
                placeholder="Select Deminimis Type"
              />
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item
              labelCol={{ span: 24 }}
              name="deminimis_percent"
              label="Deminimis Percent"
              rules={[{ required: true }]}
            >
              <Input
                min={1}
                type="number"
                placeholder="Enter Deminimis Percent"
              />
            </Form.Item>
          </Col>

          <Col md={24}>
            <Form.Item dependencies={["deminimis"]}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue("deminimis") === "deminimis_weight" && (
                    <>
                      <Row gutter={12} style={{ marginBottom: 12 }}>
                        <Col md={8}>
                          <Form.Item>
                            {messages["data.accounting.begin.code"].toString()}
                          </Form.Item>
                        </Col>
                        <Col md={14}>
                          <Form.Item>Weight</Form.Item>
                        </Col>
                      </Row>
                      <Row
                        gutter={16}
                        key={ecus_input?.ecus_code}
                        style={{ marginBottom: 12 }}
                      >
                        <Col md={8}>
                          <Form.Item>
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                position: "absolute",
                                left: "-8px",
                              }}
                            >
                              *
                            </span>
                            {ecus_input?.ecus_code}
                          </Form.Item>
                        </Col>
                        <Col md={16}>
                          <Form.Item
                            name={ecus_input?.ecus_code}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input
                              min={1}
                              type="number"
                              placeholder="Enter Weight"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {[...bangke_data].map((field) => (
                        <Row
                          gutter={16}
                          key={field.key}
                          style={{ marginBottom: 12 }}
                        >
                          <Col md={8}>
                            <Form.Item>{field?.code}</Form.Item>
                          </Col>
                          <Col md={16}>
                            <Form.Item name={field.code}>
                              <Input
                                min={1}
                                type="number"
                                placeholder="Enter Weight"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )
                );
              }}
            </Form.Item>
          </Col>

          <Col md={24}></Col>
        </AppRowContainer>
      </Form>
    </Modal>
  );
};

export default forwardRef(DeminimisModal);
