import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";

export const saveHeaderFormA = (body) => {
  return jwtAxios.post(URL_API.CO_FORM.SAVE_HEADER, body);
};

const useSaveHeaderFormA = () => {
  const { messages } = useIntl();

  return useMutation({
    mutationFn: saveHeaderFormA,
    onSuccess: (res) => {
      const { Message } = res;
      message.success(Message);
    },
    onError: (err) => {
      message.error(err?.detail?.toString());
    },
  });
};

export default useSaveHeaderFormA;
