import enLang from "./entries/en-US";
import viLang from "./entries/vi_VN";
import krLang from "./entries/ko_KR";
import itLang from "./entries/it_IT";

const AppLocale = {
  en: enLang,
  vi: viLang,
  ko: krLang,
  it: itLang,
};

export default AppLocale;
