import languageData from "./data";

import { Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import { MdLanguage } from "react-icons/md";
import { ThemeDirection } from "../../../shared/constants/AppEnums";
import { useLayoutActionsContext } from "../../utility/AppContextProvider/LayoutContextProvider";
import {
  useLocaleActionsContext,
  useLocaleContext,
} from "../../utility/AppContextProvider/LocaleContextProvide";
import "./index.style.less";
import { IoIosArrowDown } from "react-icons/io";

const AppLanguageSwitcher = () => {
  const { rtlLocale, locale } = useLocaleContext();
  const { updateLocale } = useLocaleActionsContext();
  const { updateDirection } = useLayoutActionsContext();

  const changeLanguage = (language) => {
    if (rtlLocale.indexOf(language.locale) !== -1) {
      updateDirection(ThemeDirection.RTL);
    } else {
      updateDirection(ThemeDirection.LTR);
    }
    localStorage.setItem("locale", JSON.stringify(language));
    updateLocale(language);
  };

  const menu = (
    <Menu id="language-switcher" className="language-switcher ">
      {languageData.map((language, index) => (
        <Menu.Item key={index} onClick={() => changeLanguage(language)}>
          <div className="">
            <div>{language.name}</div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayStyle={{ zIndex: 1052 }}
      >
        <a className="ant-dropdown-link langBtn">
          <span className="lang-text">
            {locale?.locale
              ? locale?.locale !== "it"
                ? locale?.locale.toUpperCase()
                : "CODE"
              : "EN"}
          </span>
          <span className="lang-arrow">
            <IoIosArrowDown onClick={(e) => e.preventDefault()} />
          </span>
        </a>
      </Dropdown>
    </>
  );
};

export default AppLanguageSwitcher;

AppLanguageSwitcher.defaultProps = {
  iconOnly: false,
};

AppLanguageSwitcher.propTypes = {
  iconOnly: PropTypes.bool,
};
