import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@uni/services/endpoint";
import { useQuery } from "react-query";

const getAllPsrByHs = async (params) => {
  const res = await jwtAxios.get(URL_API.PSR.GET_ALL_BY_HS, { params });
  return res;
};

const useGetAllPsrByHs = (params) => {
  const { data, ...rest } = useQuery(
    [URL_API.PSR.GET_ALL_BY_HS, params],
    () => getAllPsrByHs(params),
    {
      enabled: !!params.hs,
    }
  );

  const result =
    data?.results?.reduce((acc, curr) => {
      const currHs = curr?.hs || "";
      if (currHs) acc[currHs] = curr;

      return acc;
    }, {}) || {};

  return { ...rest, data: result };
};

export default useGetAllPsrByHs;
